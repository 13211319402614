// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/builds/magic/marketing/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/builds/magic/marketing/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-experiences-js": () => import("/builds/magic/marketing/src/pages/experiences.js" /* webpackChunkName: "component---src-pages-experiences-js" */),
  "component---src-pages-experiences-enterprise-js": () => import("/builds/magic/marketing/src/pages/experiences/enterprise.js" /* webpackChunkName: "component---src-pages-experiences-enterprise-js" */),
  "component---src-pages-experiences-providers-js": () => import("/builds/magic/marketing/src/pages/experiences/providers.js" /* webpackChunkName: "component---src-pages-experiences-providers-js" */),
  "component---src-pages-index-js": () => import("/builds/magic/marketing/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-network-js": () => import("/builds/magic/marketing/src/pages/network.js" /* webpackChunkName: "component---src-pages-network-js" */),
  "component---src-pages-people-js": () => import("/builds/magic/marketing/src/pages/people.js" /* webpackChunkName: "component---src-pages-people-js" */),
  "component---src-pages-privacy-js": () => import("/builds/magic/marketing/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-tech-js": () => import("/builds/magic/marketing/src/pages/tech.js" /* webpackChunkName: "component---src-pages-tech-js" */),
  "component---src-pages-tech-5-g-js": () => import("/builds/magic/marketing/src/pages/tech/5g.js" /* webpackChunkName: "component---src-pages-tech-5-g-js" */),
  "component---src-pages-tech-cellular-js": () => import("/builds/magic/marketing/src/pages/tech/cellular.js" /* webpackChunkName: "component---src-pages-tech-cellular-js" */),
  "component---src-pages-terms-js": () => import("/builds/magic/marketing/src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-waitlist-js": () => import("/builds/magic/marketing/src/pages/waitlist.js" /* webpackChunkName: "component---src-pages-waitlist-js" */),
  "component---src-pages-waitlist-success-js": () => import("/builds/magic/marketing/src/pages/waitlist/success.js" /* webpackChunkName: "component---src-pages-waitlist-success-js" */)
}

exports.data = () => import("/builds/magic/marketing/.cache/data.json")

